import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getColorStateHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/color_state/', data, token)
    return(res)
}
export const setColorStateHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/color_state/', data, token)
    return(res)
}
export const editColorStateHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/color_state/${id}/update/`, data, token)
    return(res)
}
export const deleteColorStateHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/color_state/${data}/delete/`, token)
    return(res)
}