import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import Home from './pages/Home';
import { useStore } from './store/Store';
import { Route, Routes, useHref, useNavigate } from 'react-router-dom';
import LoginBox from './login_register/LoginBox';
import Loader from './components/Loader';
export default function App() {
  const isBlue=useStore(state=>state.isBlue)
  const user=useStore(state=>state.user)
  const loader=useStore(state=>state.loader)
  const token_access=useStore(state=>state.token_access)
  const history = useHref();
  const navigate=useNavigate()
  useEffect(()=>{
    if(history!=="/" && history.indexOf("change-password")===-1  && history.indexOf("register")===-1 ){
      navigate("/")
    }
  }, [])
  return (
    <div className={isBlue?"blueBox":''}>
     {loader?<Loader/>:<></>}
      <Routes>
        {token_access===null?
        <Route path="/*" element={<LoginBox/>} />:
        <Route path="/*" element={<Home/>} />}
      
      </Routes>
      <div className='history' style={{display:'none'}}>{`${history}`}</div>
    </div>
    
  )
}
