import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getPositionHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/position/', data, token)
    return(res)
}
export const getPositionModelsHttp=async(data, token)=>{
    var res=await httpRequestGet('/cars/models/', data, token)
    return(res)
}
export const setPositionHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/position/', data, token)
    return(res)
}
export const editPositionHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/position/${id}/update/`, data, token)
    return(res)
}
export const deletePositionHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/position/${data}/delete/`, token)
    return(res)
}