import { create } from "zustand";


export const useStore=create((set)=>({
    user:null,
    next:null,
    setNext:(val)=>set((state)=>({next:val})),
    like_next:"a",
    setlike_next:(val)=>set((state)=>({like_next:val})),
    number:0,
    like_page:1,
    likes:[],
    random_users:[],
    setrandomusers:(val)=>set(({random_users, number,active_id})=>{
        var a=random_users
        var b=active_id
        if(a.length>=50){
            a=a.slice(25)
            b=b-25
        }
        a=[...a, ...val]
        return({random_users:a, number:number+1, active_id:b})
    }),
    addusersrandom:(val)=>set(({random_users, number,active_id})=>{
        var a=random_users
        console.log(a)

        var b=a.slice(0, active_id)
        var c=a.slice(active_id)
        var ac_id=active_id
        a=[...b, val, ...c]
        var ids=[]
        a.map((item, key)=>{
            if(item.id===val.id && key!==ac_id){
                ids.push(key)
            }
        })
        ids.map((item)=>{
            a.splice(item, 1)
            if(item<ac_id){
                ac_id--
            }
        })
        console.log(b, c, a)
        return({random_users:a, number:number+1})
    }),
    setlikes:(val)=>set(({likes, number})=>{
        var a=likes
        a=[...a, ...val]
        return({likes:a, number:number+1})
    }),
    setrandomlike:(val)=>set(({random_users, number})=>{
        var a=random_users
        a[val].likes=a[val].likes+1
        a[val].reactions=[...a[val].reactions, {reaction:'liked'}]
        return({random_users:a, number:number+1})
    }),
    filter:null,
    loader:false,
    page_size:1,
    active_id:null,
    countries:null,
    setactive_id:(val)=>set((state)=>({active_id:val})),
    setlike_page:(val)=>set((state)=>({like_page:val})),
    setcountries:(val)=>set((state)=>({countries:val})),
    setpage_size:(val)=>set((state)=>({page_size:val})),
    hobbies:null,
    sethobbies:(val)=>set((state)=>({hobbies:val})),
    states:null,
    setstates:(val)=>set((state)=>({states:val})),
    token_access:null,
    token_refresh:null,
    isBlue:false,
    setisBlue:(val)=>set((state)=>({isBlue:!state.isBlue})),
    settoken_access:(val)=>set((state)=>({token_access:val})),
    
    setuser:(val)=>set((state)=>({user:val})),
    setfilter:(val)=>set((state)=>({filter:val})),
    setloader:(val)=>set((state)=>({loader:val})),
    settoken_refresh:(val)=>set((state)=>({token_refresh:val})),
}))