import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getITypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/itype/', data, token)
    return(res)
}
export const setITypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/itype/', data, token)
    return(res)
}
export const editITypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/itype/${id}/update/`, data, token)
    return(res)
}
export const deleteITypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/itype/${data}/delete/`, token)
    return(res)
}