import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseProductTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/product_type/', data, token)
    return(res)
}
export const setHouseProductTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/product_type/', data, token)
    return(res)
}
export const editHouseProductTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/product_type/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseProductTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/product_type/${data}/delete/`, token)
    return(res)
}