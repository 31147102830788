import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getCMTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/cmtype/', data, token)
    return(res)
}
export const setCMTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/cmtype/', data, token)
    return(res)
}
export const editCMTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/cmtype/${id}/update/`, data, token)
    return(res)
}
export const deleteCMTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/cmtype/${data}/delete/`, token)
    return(res)
}