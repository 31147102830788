import axios from "axios"
import { useStore } from "../store/Store"

export const api="https://api.uyavto.uz/api"

export const httpRequestPost=async(url, data, token, params)=>{
    var link=api+url
    if(params!==undefined){
        link+=params+"/"
    }
    if(token){
        var res=await axios.post(link, data, {
            headers:{
                "Authorization":"Bearer "+token
            }
        })
    }else{
        var res=await axios.post(link, data)
    }
   
    return(res)
}
export const httpRequestPut=async(url, data, token, params)=>{
    var link=api+url
    if(params!==undefined){
        link+=params+"/"
    }
    if(token){
        var res=await axios.put(link, data, {
            headers:{
                "Authorization":"Bearer "+token
            }
        })
    }else{
        var res=await axios.post(link, data)
    }
   
    return(res)
}
export const httpRequestPatch=async(url, data, token, params)=>{
    var link=api+url
    if(params!==undefined){
        link+=params+"/"
    }
    if(token){
        var res=await axios.patch(link, data, {
            headers:{
                "Authorization":"Bearer "+token
            }
        })
    }else{
        var res=await axios.post(link, data)
    }
   
    return(res)
}
export const httpRequestGet=async(url, params, token, id)=>{
    var link=api+url
    if(id!==undefined){
        link+=id+"/"
    }
    if(token){
        var res=await axios.get(link, {
            params:params,
            headers:{
                "Authorization":"Bearer "+token
            }
        })
    }else{
        var res=await axios.get(link, {
            params:params,
        })
    }
    
    return(res)
}
export const httpRequestDelete=async(url, token)=>{
   
        var res=await axios.delete(api+url, {
           headers:{
                "Authorization":"Bearer "+token
            }
        })
    
    
    return(res)
}

export const checkResponse=(res)=>{
    if(res.response){
        if(res.response.status && (res.response.status===200 || res.response.status===201)){
            return(true)
        }else{
            return(false)
        }
    }else if(res.status===200 || res.status===201){
        return(true)
    }else{
        return(false)
    }
}