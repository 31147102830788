import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login'
export default function LoginBox() {
  return (
    <div>
        <Routes>
        <Route path='*' element={<Login/>} />
       
        </Routes>
    </div>
  )
}
