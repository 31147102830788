import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

export default function Pagination({count, page, setpage}) {
    const downPage=()=>{
        if(page>1){
            setpage(page-1)
        }
       }
       const upPage=()=>{
        if(page<Math.ceil(count/15)){
            setpage(page+1)
        }
       }
       const setKey=(key)=>{
        return(parseInt((page-1)/7)*7+key+1)
       }
  return (
    <div className='pagination_div'>
<p>Umumiy ma'lumot soni: {count} ta</p>
    
    <div className='pagination'>
       
        <div onClick={downPage} className={`pagination_box pagination_arrow arrow_left ${page<=1?"disabled_pag":<></>}`}><IoIosArrowBack /></div>
        {new Array(Math.ceil(count/15)>7?7:Math.ceil(count/15)).fill(0).map((item, key)=>{
            return( <div key={key} onClick={()=>{setpage(setKey(key))}} className={`pagination_box pagination_number ${setKey(key)===page?"active_pag":""}`}>{setKey(key)}</div>)
        })}
       
        
        <div  onClick={upPage} className={`pagination_box pagination_arrow arrow_right  ${page>=Math.ceil(count/15)?"disabled_pag":<></>}`}><IoIosArrowForward /></div>
    </div>
    </div>
  )
}
