import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getModelsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/model/', data, token)
    return(res)
}
export const getModelsCatalogsHttp=async(data, token)=>{
    var res=await httpRequestGet('/car-selections/', data, token)
    return(res)
}
export const setModelsHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/model/', data, token)
    return(res)
}
export const editModelsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/model/${id}/update/`, data, token)
    return(res)
}
export const deleteModelsHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/model/${data}/delete/`, token)
    return(res)
}