import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getNearHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/near/', data, token)
    return(res)
}
export const setNearHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/near/', data, token)
    return(res)
}
export const editNearHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/near/${id}/update/`, data, token)
    return(res)
}
export const deleteNearHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/near/${data}/delete/`, token)
    return(res)
}