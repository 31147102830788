import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getCarServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/car/payment_type/', data, token)
    return(res)
}
export const setCarServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/car/payment_type/', data, token)
    return(res)
}
export const editCarServicePaymentTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/car/payment_type/${id}/update/`, data, token)
    return(res)
}
export const deleteCarServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/car/payment_type/${data}/delete/`, token)
    return(res)
}