import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getColorHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/color/', data, token)
    return(res)
}
export const setColorHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/color/', data, token)
    return(res)
}
export const editColorHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/color/${id}/update/`, data, token)
    return(res)
}
export const deleteColorHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/color/${data}/delete/`, token)
    return(res)
}