import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getDriveUnitHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/drive_unit/', data, token)
    return(res)
}
export const setDriveUnitHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/drive_unit/', data, token)
    return(res)
}
export const editDriveUnitHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/drive_unit/${id}/update/`, data, token)
    return(res)
}
export const deleteDriveUnitHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/drive_unit/${data}/delete/`, token)
    return(res)
}