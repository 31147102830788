import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/house/payment_type/', data, token)
    return(res)
}
export const setHouseServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/house/payment_type/', data, token)
    return(res)
}
export const editHouseServicePaymentTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/house/payment_type/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseServicePaymentTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/house/payment_type/${data}/delete/`, token)
    return(res)
}