import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getItemsInHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/items_in/', data, token)
    return(res)
}
export const setItemsInHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/items_in/', data, token)
    return(res)
}
export const editItemsInHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/items_in/${id}/update/`, data, token)
    return(res)
}
export const deleteItemsInHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/items_in/${data}/delete/`, token)
    return(res)
}