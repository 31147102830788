import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getKuzovHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/kuzov/', data, token)
    return(res)
}
export const setKuzovHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/kuzov/', data, token)
    return(res)
}
export const editKuzovHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/kuzov/${id}/update/`, data, token)
    return(res)
}
export const deleteKuzovHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/kuzov/${data}/delete/`, token)
    return(res)
}