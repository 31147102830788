import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseServiceTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/house/service_types/', data, token)
    return(res)
}
export const setHouseServiceTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/house/service_types/', data, token)
    return(res)
}
export const editHouseServiceTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/house/service_types/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseServiceTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/house/service_types/${data}/delete/`, token)
    return(res)
}