import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getBTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/b_type/', data, token)
    return(res)
}
export const setBTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/b_type/', data, token)
    return(res)
}
export const editBTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/b_type/${id}/update/`, data, token)
    return(res)
}
export const deleteBTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/b_type/${data}/delete/`, token)
    return(res)
}