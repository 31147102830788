import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getHouseSubProductTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/subproduct_type/', data, token)
    return(res)
}
export const getHouseSubProductTypeCatalogsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/houses-products/types/', data, token)
    return(res)
}
export const setHouseSubProductTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/subproduct_type/', data, token)
    return(res)
}
export const editHouseSubProductTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/subproduct_type/${id}/update/`, data, token)
    return(res)
}
export const deleteHouseSubProductTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/subproduct_type/${data}/delete/`, token)
    return(res)
}