import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getRepairHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/repair/', data, token)
    return(res)
}
export const setRepairHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/repair/', data, token)
    return(res)
}
export const editRepairHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/repair/${id}/update/`, data, token)
    return(res)
}
export const deleteRepairHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/repair/${data}/delete/`, token)
    return(res)
}