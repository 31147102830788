import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteModelsHttp, editModelsHttp, getModelsCatalogsHttp, getModelsHttp, setModelsHttp } from '../host/requests/Models'
import { useStore } from '../store/Store'
import { Button, Form, Input, Modal, Select, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
export default function Models() {
    const [data, setData]=useState([])
    const [ctype_data, setctype_data]=useState([])
    const [cmtype_data, setcmtype_data]=useState([])
    const [open, setOpen]=useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [error, setError]=useState(false)
    const [edit, setedit]=useState(null)
    const [search, setsearch]=useState(null)
    const [ctype, setctype]=useState(null)
    const [cmtype, setcmtype]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    const [form] = Form.useForm()
    useEffect(()=>{
        getCatalogData()
       
        getData(cmtype,ctype, page)
    },[page, search])

    const getCatalogData=async()=>{
      try{
        var res=await getModelsCatalogsHttp({}, token_access)
        setctype_data(res.data.ctype)
        setcmtype_data(res.data.cmtype)
       
    }catch (error){
        console.log(error)
       }
    }
   

    const getData=async(cmtype_new,ctype_new, page_new)=>{
        if(search===null || search.length===0){
            setloader(true)
        }

        try{
            var res=await getModelsHttp({name:search, page:page_new, ctype:ctype_new, cmtype:cmtype_new}, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
    const openModal=()=>{
        setOpen(true)
    }
    const closeModal=()=>{
        setOpen(false)
        form.resetFields()
        setedit(null)
        setError(false)
        
    }
   const openModalEdit=(item)=>{
           setedit(item.id)
           form.setFieldsValue(item)
           if(item.ctype!==null){
            form.setFieldValue("ctype", item.ctype.id)
           }
           if(item.cmtype!==null){
            form.setFieldValue("cmtype", item.cmtype.id)
           }
          openModal()
   }
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteModelsHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData(cmtype,ctype, page)
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
    const onFinish=async(val)=>{
      setloader(true)
        try{
            if(edit===null){
                var res=await setModelsHttp(val, token_access)
            }else{
                var res=await editModelsHttp(edit, val, token_access)
            }
            
            successAlert("Ma'lumot qo'shildi")
            setpage(1)
            closeModal()
            getData(cmtype,ctype, page)
        }catch (error){
          setloader(false)
            dangerAlert("Ma'lumot qo'shilmadi")
        }
    }
    const checkHasOrNot=(val, lan)=>{
        if(edit===null){
            var a=data.filter(x=>x[`name_${lan}`].toLowerCase().replaceAll(" ", "")===val.toLowerCase().replaceAll(" ", ""))
            if(a.length!==0){
                setError(true)
                dangerAlert("Bunday qiziqish bazaga qo'shilgan")
            }else{
                setError(false)
            }
        }
    }
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
           },
           {
            title: 'Nomi (uz)',
            dataIndex: 'name_uz',
           },
           {
            title: 'Nomi (ru)',
            dataIndex: 'name_ru',
           },
           {
            title: 'Avtomobil turi',
            dataIndex: 'cmtype',
            render:(id)=>{
              if(id!==null){
                return(id.name_uz)
              }else{
                return('-')
              }
            }
           },
           {
            title: 'Marka',
            dataIndex: 'ctype',
            render:(id)=>{
              if(id!==null){
                return(id.name_uz)
              }else{
                return('-')
              }
            }
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: '',
            render:(id)=>{return(<Button onClick={()=>{openModalEdit(id)}} type="primary" className='warning_btn' shape="circle" icon={<BsPencilFill />} />)},
           }
       
      ];
      const editDataGet=(e)=>{
        setctype(e.target.value==='-1'?null:e.target.value)
       
        setpage(1)
        getData(cmtype, e.target.value==='-1'?null:e.target.value, 1)
      }
      const editDataGetCM=(e)=>{
        setcmtype(e.target.value==='-1'?null:e.target.value)
       
        setpage(1)
        getData(e.target.value==='-1'?null:e.target.value, ctype, 1)
      }
  return (
    <>
     <div className='contentHeader'>
     <Header addData={openModal} setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
     <div style={{display:'flex'}}>
    <div className='select_box'>
         <label>Avtomobil turi</label>
         <select className='input select' onChange={(e)=>{editDataGetCM(e)}} value={cmtype}>
         <option value={-1}>Barchasi</option>
              {cmtype_data.map((item,key)=>{
                return(<option value={item.id}>{item.name_uz}</option>)
              })}
         </select>
     </div>
     <div className='select_box'>
         <label>Marka</label>
         <select className='input select' onChange={(e)=>{editDataGet(e)}} value={ctype}>
         <option value={-1}>Barchasi</option>
              {ctype_data.map((item,key)=>{
                return(<option value={item.id}>{item.name_uz}</option>)
              })}
         </select>
     </div>
     </div>

    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
    <Modal footer={false} title={`Ma'lumot ${edit===null?"qo'shish":"o'zgartirish"}`} open={open} onOk={closeModal} onCancel={closeModal}>
    <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    
  >
    <Form.Item
      label="Avtomobil turi"
      name="cmtype"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
     <Select
      options={
        cmtype_data.map((item, key)=>{
          return( { value: item.id, label: item.name_uz })
        })
      }
    />
    </Form.Item>
    <Form.Item
      label="Marka"
      name="ctype"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
     <Select
      options={
        ctype_data.map((item, key)=>{
          return( { value: item.id, label: item.name_uz })
        })
      }
    />
    </Form.Item>
    <Form.Item
      label="Nomi (uz)"
      name="name_uz"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input className='input' />
    </Form.Item>
    
    <Form.Item
      label="Nomi (ru)"
      name="name_ru"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input className='input' />
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
      <Button onClick={closeModal}>
        Yopish
      </Button>
      <Button disabled={error} type="primary"  htmlType="submit">
        Saqlash
      </Button>
      
      </div>
    </Form.Item>
  </Form>

    </Modal>
    {contextHolder}
    </div>
    </div>
    </>
  )
}

