import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getEngineTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/engine_type/', data, token)
    return(res)
}
export const setEngineTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/engine_type/', data, token)
    return(res)
}
export const editEngineTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/engine_type/${id}/update/`, data, token)
    return(res)
}
export const deleteEngineTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/engine_type/${data}/delete/`, token)
    return(res)
}