import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getTransmissionHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/transmission/', data, token)
    return(res)
}
export const setTransmissionHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/transmission/', data, token)
    return(res)
}
export const editTransmissionHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/transmission/${id}/update/`, data, token)
    return(res)
}
export const deleteTransmissionHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/transmission/${data}/delete/`, token)
    return(res)
}