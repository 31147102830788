import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
export default function Header({addData, setpage, setsearch, search, filter, openFilter}) {
  const onChange=(val)=>{
    setsearch(val)
    setpage(1)
    console.log(val)
  }
  return (
    <div className='headerBox'>
       <div className='head_btn_box'>{addData!==undefined?<Button className='btn_head' type='primary' onClick={addData}>Ma'lumot qo'shish</Button>:<></>} 
       {filter?<Button className='btn_head filter_btn' type='primary' onClick={openFilter}>Filterlar</Button>:<></>} </div>
       {search!==undefined?<Input className='input' value={search}  prefix={<IoSearch className='input_icon'/>} onChange={(val)=>{onChange(val.target.value)}} placeholder='Qidirish'/>:<></>} 
    </div>
  )
}
