import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getSanuzelHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/sanuzel/', data, token)
    return(res)
}
export const setSanuzelHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/sanuzel/', data, token)
    return(res)
}
export const editSanuzelHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/sanuzel/${id}/update/`, data, token)
    return(res)
}
export const deleteSanuzelHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/sanuzel/${data}/delete/`, token)
    return(res)
}