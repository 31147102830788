import React from 'react'
import '../css/Loader.css'
export default function Loader() {
  return (
    <div className='loader_box'>
     <section className="sec-loading">
  <div className="one">
  </div>
</section>
    </div>
  )
}
