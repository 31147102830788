import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getCTypeHttp=async(data, token)=>{
    var res=await httpRequestGet('/admin/ctype/', data, token)
    return(res)
}
export const getCTypeCatalogsHttp=async(data, token)=>{
    var res=await httpRequestGet('/car-selections/', data, token)
    return(res)
}
export const setCTypeHttp=async(data, token)=>{
    var res=await httpRequestPost('/admin/ctype/', data, token)
    return(res)
}
export const editCTypeHttp=async(id, data, token)=>{
    var res=await httpRequestPatch(`/admin/ctype/${id}/update/`, data, token)
    return(res)
}
export const deleteCTypeHttp=async(data, token)=>{
    var res=await httpRequestDelete(`/admin/ctype/${data}/delete/`, token)
    return(res)
}