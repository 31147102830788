import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteKuzovHttp, editKuzovHttp, getKuzovHttp, setKuzovHttp } from '../host/requests/Kuzov'
import { useStore } from '../store/Store'
import { Button, Form, Input, Modal, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
export default function Kuzov() {
    const [data, setData]=useState([])
    const [open, setOpen]=useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [error, setError]=useState(false)
    const [edit, setedit]=useState(null)
    const [search, setsearch]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    const [form] = Form.useForm()
    useEffect(()=>{
        getData()
    },[page, search])
    const getData=async()=>{
        if(search===null || search.length===0){
            setloader(true)
        }

        try{
            var res=await getKuzovHttp({name:search, page:page}, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
    const openModal=()=>{
        setOpen(true)
    }
    const closeModal=()=>{
        setOpen(false)
        form.resetFields()
        setedit(null)
        setError(false)
        
    }
   const openModalEdit=(item)=>{
           setedit(item.id)
           form.setFieldsValue(item)
           openModal()
   }
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteKuzovHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
    const onFinish=async(val)=>{
      setloader(true)
        try{
            if(edit===null){
                var res=await setKuzovHttp(val, token_access)
            }else{
                var res=await editKuzovHttp(edit, val, token_access)
            }
            
            successAlert("Ma'lumot qo'shildi")
            setpage(1)
            closeModal()
            getData()
        }catch (error){
          setloader(false)
            dangerAlert("Ma'lumot qo'shilmadi")
        }
    }
    const checkHasOrNot=(val, lan)=>{
        if(edit===null){
            var a=data.filter(x=>x[`name_${lan}`].toLowerCase().replaceAll(" ", "")===val.toLowerCase().replaceAll(" ", ""))
            if(a.length!==0){
                setError(true)
                dangerAlert("Bunday qiziqish bazaga qo'shilgan")
            }else{
                setError(false)
            }
        }
    }
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
           },
           {
            title: 'Nomi (uz)',
            dataIndex: 'name_uz',
           },
           {
            title: 'Nomi (ru)',
            dataIndex: 'name_ru',
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: '',
            render:(id)=>{return(<Button onClick={()=>{openModalEdit(id)}} type="primary" className='warning_btn' shape="circle" icon={<BsPencilFill />} />)},
           }
       
      ];
  return (
    <>
     <div className='contentHeader'>
     <Header addData={openModal} setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
    <Modal footer={false} title={`Ma'lumot ${edit===null?"qo'shish":"o'zgartirish"}`} open={open} onOk={closeModal} onCancel={closeModal}>
    <Form
    name="add"
    form={form}
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    
  >
    <Form.Item
      label="Nomi (uz)"
      name="name_uz"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input onChange={(e)=>{checkHasOrNot(e.target.value, "uz")}} className='input' />
    </Form.Item>
    <Form.Item
      label="Nomi (ru)"
      name="name_ru"
      rules={[
       
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input onChange={(e)=>{checkHasOrNot(e.target.value, "uz")}} className='input' />
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
className='btn_form_div'
    >
        <div className='btn_box'>
      <Button onClick={closeModal}>
        Yopish
      </Button>
      <Button disabled={error} type="primary"  htmlType="submit">
        Saqlash
      </Button>
      
      </div>
    </Form.Item>
  </Form>

    </Modal>
    {contextHolder}
    </div>
    </div>
    </>
  )
}

